import Radio from "../../../components/Forms/Radio";
import { createDataType } from "../../../types/dataTypesType";
import { officialUniqueIdentifierFormField } from "./constants";
import { InputFieldsComponent } from "./InputFields";

type dataTypeFieldsProps = {
  data: createDataType;
  changeInitValues: (updateInitValues: createDataType) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  updateFormError: (errStatus: any) => void;
};

export const DataTypeFieldsComponent = ({
  data,
  changeInitValues,
  handleChange,
  updateFormError,
}: dataTypeFieldsProps) => {
  return (
    <div className="relative overflow-hidden pb-8 mt-10 px-10 -ml-10 bg-[#F1F4F4]">
      <div className="">
        <h2 className="font-open-sans font-normal text-xl flat-gray-color mt-10">
          Fields
        </h2>

        <div className="rounded-md overflow-hidden border border-[#C4C4C4] bg-white mt-3.5 py-5 px-5 mb-8">
          <p className="font-open-sans font-bold text-status-label-color text-small-custom-1">
            Unique identifiers
          </p>
          {data.format === "Custom" ? (
            <div className="font-open-sans font-normal text-light-black-color text-base">
              The following fields are automatically added for all test imports.
              The user will be able to select which fields to use to uniquely
              identify student records.
            </div>
          ) : (
            <>
              <div className="font-open-sans font-normal text-light-black-color text-base mb-3 mt-0.25">
                The following fields will be required by the user and used to
                uniquely identify student records.
              </div>

              <div className="official-identifier-radio-group">
                <Radio
                  keyId={0}
                  {...officialUniqueIdentifierFormField}
                  value={data.keyGroup}
                  disabled={false}
                  values={data}
                  handleChange={handleChange}
                />
              </div>
            </>
          )}
          {data.format === "Custom" && (
            <ul className="font-open-sans text-base list-disc pl-8 font-bold">
              <li>First Name, Last Name, and Date of Birth</li>
              <li>State Student ID and Last Name</li>
              <li>Student ID and Last Name</li>
            </ul>
          )}
        </div>
        <InputFieldsComponent
          data={data}
          updateFormError={updateFormError}
          changeInitValues={changeInitValues}
        />
      </div>
    </div>
  );
};
