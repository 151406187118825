import getValue from "./getValue";

type RadioProps = {
  keyId: number;
  label: string | undefined;
  name: string;
  type: any;
  options: any;
  handleChange: (e: any) => void;
  values: any;
  value: any;
  disabled: boolean;
  isHorizontal?: boolean;
};

const Radio = ({
  label,
  name,
  type,
  options,
  handleChange,
  values,
  disabled,
  isHorizontal,
}: RadioProps) => {
  return (
    <div className={isHorizontal ? "mb-4" : ""}>
      <div className="block tracking-wide font-open-sans text-light-black-color text-small-custom-1 font-normal mb-1">
        {label}
      </div>
      <div className={isHorizontal ? "flex" : ""}>
        {options.map((option: any, index: number) => (
          <div
            className={`flex items-center ${
              !isHorizontal && options.length - 1 !== index ? "radio-group" : ""
            }`}
            key={index.toString()}
          >
            <input
              type={type}
              name={name}
              onClick={(e) => {
                handleChange(e);
              }}
              value={option.value}
              defaultChecked={
                getValue(name, values) === option.value ||
                (!getValue(name, values) && option.checked)
              }
              id={name + option.label}
              key={index.toString()}
              disabled={disabled}
              className="mr-1 mt-0.5"
            />
            <label
              htmlFor={name + option.label}
              className="mr-8 ml-2 block tracking-wide font-open-sans text-light-black-color text-small-custom-3 font-normal"
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Radio;
