import Icon from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { LeftArrow } from "../../../utils/icons/svgIcons";
import { statesData } from "../create/constants";

const ArrowLeftIcon = (props: any) => (
  <Icon
    tabIndex={1}
    component={LeftArrow}
    {...props}
    id="create-back-arrow-icon"
  />
);

export const PreviewDataTypeHeader = ({ dataType }: any) => {
  const { name, status, format, availability, displayScores } = dataType;
  const navigate = useNavigate();
  const showAvailability = () => {
    const statesObject = statesData.find(
      (obj: any) => obj.value == availability
    );
    return statesObject?.label;
  };

  return (
    <>
      <button
        onClick={() => navigate(-1)}
        type="button"
        className="flex items-baseline -ml-3 mt-5"
      >
        <ArrowLeftIcon className="h-2 w-1" />
        <div className="border-b-2 border-[#C4C4C4] text-flat-gray-color text-small-custom-3 font-semibold ml-2">
          Back
        </div>
      </button>
      <h1 className="text-2xl font-bold mt-4 text-flat-gray-color">Preview</h1>
      <h2 className="text-xl font-normal mt-10 mb-5">Details</h2>
      <div className="mb-5">
        <p className="font-bold text-small-custom-1 text-status-label-color">
          Status
        </p>
        <span
          id={
            status === "Published"
              ? "grouping-badge-published"
              : status === "UnPublished"
              ? "grouping-badge-unpublished"
              : status === "Draft"
              ? "grouping-badge-draft"
              : "grouping-badge-test"
          }
        >
          {status}
        </span>
      </div>
      <div className="mb-5">
        <p className="font-bold text-small-custom-1 text-status-label-color">
          File Format
        </p>
        <p className="text-small-custom-1 text-status-label-color">
          {format === "Custom" ? "Custom format" : "Official format"}
        </p>
      </div>
      <div className="mb-5">
        <p className="font-bold text-small-custom-1 text-status-label-color">
          Name
        </p>
        <p className="text-small-custom-1 text-status-label-color">{name}</p>
      </div>
      <div className="mb-5">
        <p className="font-bold text-small-custom-1 text-status-label-color">
          Availability
        </p>
        <p className="text-small-custom-1 text-status-label-color">
          {showAvailability()}
        </p>
      </div>
      <div className="border-b-[1px] pb-10">
        <p className="font-bold text-small-custom-1 text-status-label-color">
          Display scores in Student Folder
        </p>
        <p className="text-small-custom-1 text-status-label-color">
          {displayScores === true ? "Yes" : "No"}
        </p>
      </div>
    </>
  );
};
