import "./CreateDataType.css";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { CheckDuplicateDataType, createNewDataType } from "../../../api";
import { Loader } from "../../../components";
import Checkbox from "../../../components/Forms/Checkbox";
import Dropdown from "../../../components/Forms/Dropdown";
import formValues from "../../../components/Forms/formValues";
import Radio from "../../../components/Forms/Radio";
import TextField from "../../../components/Forms/TextField";
import useForm from "../../../components/Forms/useForm";
import { createDataType, dataTypeFields } from "../../../types/dataTypesType";
import { ACCEPTABLE_VALUES, TEXT } from "../../../utils";
import { errorAlert, successAlert } from "../../../utils/toast/Toast";
import { changeFormatRadioGroup, validateUniqueAttributes } from "../common";
import { addNonEditableField } from "../edit/EditDataType";
import { initialValues, formData } from "./constants";
import { DataTypeDetailsFooter } from "./DataTypeDetailsFooter";
import { DataTypeDetailsHeader } from "./DataTypeDetailsHeader";
import { DataTypeFieldsComponent } from "./DataTypeFields";

export const deleteNonEditableField = (inputFields: dataTypeFields[]) => {
  return inputFields.map((field: dataTypeFields) => {
    delete field?.nonEditable;
  });
};

export const CreateDataType = () => {
  const [initValues, setInitValues] = useState<createDataType>({
    ...initialValues,
  });
  const [formError, setFormError] = useState({
    fieldErr: false,
    columnErr: false,
  });
  const [loading, setLoading] = useState(false);
  const [disableSavePreviewbutton, setDisableSavePreviewbutton] =
    useState(true);
  let saveAndClose = false;
  const navigate = useNavigate();
  const { groupingId } = useParams();
  const [duplicateFieldNames, setDuplicateFielsNames] = useState<{
    [key: string]: boolean;
  }>({});
  const prevName = useRef("");
  const setSaveAndClose = () => {
    saveAndClose = true;
  };

  const saveDataType = async (dataType: createDataType, groupingsId: any) => {
    setLoading(true);
    deleteNonEditableField(dataType.fields);
    createNewDataType(dataType, groupingsId)
      .then((res) => {
        setLoading(false);
        if (res.status === 201) {
          if (saveAndClose === true) {
            navigate("/");
            setTimeout(
              () => successAlert("Successfully created new data type."),
              0
            );
          } else {
            if (res.data.format === "Official") {
              navigate(`/datatype/preview/${res.data.id}`, {
                state: res.data,
              });
            } else {
              navigate(`/datatype/preview/${res.data.id}`, {
                state: res.data,
              });
            }
          }
        }
      })
      .catch((error) => {
        addNonEditableField(dataType.fields);
        setLoading(false);
        setTimeout(() => {
          error.response.status === 409
            ? errorAlert(
                "Data type already exists! Please try with different name."
              )
            : errorAlert(
                "Error while creating the new Data type! Please try again."
              );
        }, 0);
        console.log("Error while creating new Data type", error);
      });
  };

  let { values, handleSubmit } = useForm({
    initialValues: initValues,
    onSubmit: async () => {
      if (!disableSavePreviewbutton) {
        const isUniqueFieldPresent =
          initValues.fields &&
          initValues.fields.filter((field) => field.isUnique);

        try {
          validateUniqueAttributes(isUniqueFieldPresent);
        } catch (err: any) {
          errorAlert(err.message);
          return;
        }

        await saveDataType(initValues, groupingId);
      } else {
        errorAlert("DataType already exists");
      }
    },
  });

  useEffect(() => {
    handleChange({
      target: {
        name: "format",
        value: initValues.format,
        type: "radio",
      },
    });
    return () => setDisableSavePreviewbutton(true);
  }, []);

  const handleChange = (event: any) => {
    const { target } = event;
    const { name, value, type } = target;
    const formValuesObj: { [key: string]: any } = initValues;
    if (type === "text" && name === "name" && !disableSavePreviewbutton)
      setDisableSavePreviewbutton(true);
    if (type === "checkbox") {
      formValuesObj[name] = event.currentTarget.checked ? true : false;
    } else {
      formValuesObj[name] = value;
    }
    if (type === "radio") {
      changeFormatRadioGroup(name, formValuesObj, updateFormError);
    }

    let newValues = { values: { ...initialValues, ...formValuesObj } };
    const valuesObj = formValues(newValues);
    setInitValues(valuesObj);
    return valuesObj;
  };

  const handleBlur = (event: any) => {
    if (event?.target?.name === "name") {
      let previousName = prevName.current;
      let currentName = initValues.name;
      if (duplicateFieldNames[currentName]) {
        if (previousName !== currentName) {
          errorAlert("DataType already exists");
        }
        setDisableSavePreviewbutton(true);
        return true;
      }
      if (currentName && previousName !== currentName) {
        prevName.current = initValues.name;
        return CheckDuplicateDataType(currentName)
          .then(() => {
            setDisableSavePreviewbutton(false);
            return false;
          })
          .catch(() => {
            duplicateFieldNames[currentName] = true;
            setDuplicateFielsNames(duplicateFieldNames);
            errorAlert("DataType already exists");
            setDisableSavePreviewbutton(true);
            return true;
          });
      } else setDisableSavePreviewbutton(false);
    }
    return false;
  };

  const saveFormData = () => {
    const submitBtn = document.getElementById("submitForm");
    const isUniquePresent = initialValues.fields.filter(
      (field) => field.isUnique
    ) as any;

    if (formError.columnErr || formError.fieldErr) {
      errorAlert(
        "Error while saving data type. Duplicate Field Name or Column in File found!."
      );
    } else if (
      isUniquePresent.length > 0 &&
      (!isUniquePresent[0].acceptableValues ||
        isUniquePresent[0].inputType !== TEXT ||
        isUniquePresent[0].validationType !== ACCEPTABLE_VALUES)
    ) {
      errorAlert(
        "Field marked as sub test type must have input type as Text and contain AcceptableValues"
      );
    } else {
      submitBtn?.click();
    }
  };

  const changeInitValues = (updateInitValues: createDataType) => {
    setInitValues(updateInitValues);
  };
  const updateFormError = (errStatus: any) => {
    setFormError({ ...formError, ...errStatus });
  };

  const renderFormFields = (formField: any, keyId: number) => {
    return (
      <div key={keyId} className={`${formField.name} flex-1`}>
        {formField.type === "text" && (
          <TextField
            key={keyId}
            {...formField}
            values={initValues}
            handleChange={handleChange}
            onblur={handleBlur}
          />
        )}
        {formField.type === "dropdown" && (
          <Dropdown
            key={keyId}
            {...formField}
            values={initValues}
            handleChange={handleChange}
          />
        )}
        {formField.type === "radio" && (
          <Radio
            key={keyId}
            {...formField}
            values={initValues}
            handleChange={handleChange}
          />
        )}
        {formField.type === "checkbox" && (
          <Checkbox
            key={keyId}
            {...formField}
            values={initValues}
            handleChange={handleChange}
          />
        )}
      </div>
    );
  };
  return (
    <>
      {loading && <Loader size="medium" />}
      {!loading && (
        <div className="pl-10">
          <ToastContainer />
          <DataTypeDetailsHeader importStatus="Draft" importType="Create" />
          <div className="font-open-sans font-normal not-italic">
            <form
              className="bg-white rounded pt-[25px] pb-8 mb-4 data-type-form"
              onSubmit={handleSubmit}
              key={values.id ? values.id : ""}
              id={values.id ? values.id : ""}
            >
              {[...formData].map((formField: any, keyId: number) => {
                return renderFormFields(formField, keyId);
              })}
              <DataTypeFieldsComponent
                data={initValues}
                changeInitValues={changeInitValues}
                handleChange={handleChange}
                updateFormError={updateFormError}
              />
              <button id="submitForm" type="submit" style={{ display: "none" }}>
                Submit
              </button>
            </form>
          </div>
          <DataTypeDetailsFooter
            buttonFunctionality={disableSavePreviewbutton}
            saveFormData={saveFormData}
            setSaveAndClose={setSaveAndClose}
          />
        </div>
      )}
    </>
  );
};
