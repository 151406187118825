import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  CheckDuplicateDataType,
  getDataType,
  updateDataType,
} from "../../../api";
import { Loader } from "../../../components";
import Checkbox from "../../../components/Forms/Checkbox";
import Dropdown from "../../../components/Forms/Dropdown";
import formValues from "../../../components/Forms/formValues";
import Radio from "../../../components/Forms/Radio";
import TextField from "../../../components/Forms/TextField";
import useForm from "../../../components/Forms/useForm";
import { dataTypeFields, editDataType } from "../../../types/dataTypesType";
import { errorAlert, successAlert } from "../../../utils/toast/Toast";
import { changeFormatRadioGroup, validateUniqueAttributes } from "../common";
import {
  initialValues,
  formData,
  defaultUniqueFieldsName,
} from "../create/constants";
import { deleteNonEditableField } from "../create/CreateDataType";
import { DataTypeDetailsFooter } from "../create/DataTypeDetailsFooter";
import { DataTypeDetailsHeader } from "../create/DataTypeDetailsHeader";
import { DataTypeFieldsComponent } from "../create/DataTypeFields";

export const addNonEditableField = (inputFields: dataTypeFields[]) => {
  return inputFields
    .filter((field: dataTypeFields) =>
      defaultUniqueFieldsName.includes(field.name)
    )
    .map((field: dataTypeFields) => {
      field.nonEditable = true;
    });
};

export const EditDataType = () => {
  const [initValues, setInitValues] = useState<editDataType>(
    {} as editDataType
  );
  const [formError, setFormError] = useState({
    fieldErr: false,
    columnErr: false,
  });
  const [loading, setLoading] = useState(true);
  const [disableSavePreviewbutton, setDisableSavePreviewbutton] =
    useState(false);
  const [duplicateFieldNames, setDuplicateFielsNames] = useState<{
    [key: string]: boolean;
  }>({});
  let saveAndClose = false;
  const navigate = useNavigate();
  const { dataTypeId } = useParams();
  const prevName = useRef("");

  const setSaveAndClose = () => {
    saveAndClose = true;
  };

  const getDataTypeDetails = (dataTypesId: any) => {
    getDataType(dataTypesId)
      .then((res) => {
        addNonEditableField(res.data.fields);
        setInitValues(res.data);
        prevName.current = res?.data?.name;
      })
      .catch((error) => console.log("Error while fetching data Type", error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getDataTypeDetails(dataTypeId);
    return () => {
      setDisableSavePreviewbutton(false);
    };
  }, []);

  const handleBlur = (event: any) => {
    if (event?.target?.name === "name") {
      setDisableSavePreviewbutton(true);
      let previousName = prevName.current;
      let currentName = initValues.name;
      if (duplicateFieldNames[currentName]) {
        if (previousName !== currentName) {
          errorAlert("DataType already exists");
        }
        setDisableSavePreviewbutton(true);
        return true;
      }
      if (currentName && previousName !== currentName) {
        return CheckDuplicateDataType(currentName)
          .then(() => {
            setDisableSavePreviewbutton(false);
            return false;
          })
          .catch(() => {
            duplicateFieldNames[currentName] = true;
            setDuplicateFielsNames(duplicateFieldNames);
            errorAlert("DataType already exists");
            setDisableSavePreviewbutton(true);
            return true;
          });
      } else setDisableSavePreviewbutton(false);
    }
    return false;
  };

  const saveEditedDataType = async (dataType: editDataType) => {
    try {
      deleteNonEditableField(dataType.fields);
      const { id, groupingId }: any = dataType;
      const updatedDataType = { ...dataType };
      delete updatedDataType.id;
      delete updatedDataType.groupingId;
      delete updatedDataType.MB;
      delete updatedDataType.MD;
      delete updatedDataType.CB;
      delete updatedDataType.CT;
      delete updatedDataType.status;
      delete updatedDataType.CBE;
      delete updatedDataType.MBE;
      setLoading(true);
      const res = await updateDataType(updatedDataType, groupingId, id);
      setLoading(false);
      if (res.status === 200) {
        if (saveAndClose === true) {
          navigate("/");
          setTimeout(
            () => successAlert("Successfully edited the data type."),
            0
          );
        } else {
          if (res.data.format === "Official") {
            navigate(`/datatype/preview/${res.data.id}`, {
              state: res.data,
            });
          } else {
            navigate(`/datatype/preview/${res.data.id}`, {
              state: res.data,
            });
          }
        }
      }
    } catch (error: any) {
      addNonEditableField(dataType.fields);
      setLoading(false);
      setTimeout(() => {
        error.response.status === 409
          ? errorAlert(
              "Data type already exists! Please try with different name."
            )
          : errorAlert("Error while updating the Data type! Please try again.");
      }, 0);
      console.log("Error while editing the Data type", error);
    }
  };

  let { handleSubmit } = useForm({
    initialValues: initValues,
    onSubmit: async () => {
      if (!disableSavePreviewbutton && initValues !== null) {
        const isUniqueFieldPresent =
          initValues.fields &&
          initValues.fields.filter((field) => field.isUnique);

        try {
          validateUniqueAttributes(isUniqueFieldPresent);
        } catch (err: any) {
          errorAlert(err.message);
          return;
        }

        await saveEditedDataType(initValues);
      } else {
        errorAlert("DataType already exists");
      }
    },
  });

  const handleChange = (event: any) => {
    const { target } = event;
    const { name, value, type } = target;
    const formValuesObj: { [key: string]: any } =
      initValues !== null ? initValues : {};
    if (type === "text" && name === "name" && !disableSavePreviewbutton)
      setDisableSavePreviewbutton(true);
    if (type === "checkbox") {
      formValuesObj[name] = event.currentTarget.checked ? true : false;
    } else {
      formValuesObj[name] = value;
    }
    if (type === "radio") {
      changeFormatRadioGroup(name, formValuesObj, updateFormError);
    }
    let newValues = { values: { ...initialValues, ...formValuesObj } };
    const valuesObj = formValues(newValues);
    setInitValues(valuesObj);
    return valuesObj;
  };

  const saveFormData = () => {
    const submitBtn = document.getElementById("submitForm");
    if (formError.columnErr || formError.fieldErr) {
      errorAlert(
        "Error while saving data type. Duplicate Field Name or Column in File found!."
      );
    } else {
      submitBtn?.click();
    }
  };

  const changeInitValues = (updateInitValues: editDataType) => {
    setInitValues(updateInitValues);
  };
  const updateFormError = (errStatus: any) => {
    setFormError({ ...formError, ...errStatus });
  };

  const renderFormFields = (formField: any, keyId: number) => {
    return (
      <div key={keyId} className={`${formField.name} flex-1`}>
        {formField.type === "text" && (
          <TextField
            key={keyId}
            {...formField}
            values={initValues}
            handleChange={handleChange}
            onblur={handleBlur}
          />
        )}
        {formField.type === "dropdown" && (
          <Dropdown
            key={keyId}
            {...formField}
            values={initValues}
            handleChange={handleChange}
          />
        )}
        {formField.type === "radio" && (
          <Radio
            key={keyId}
            {...formField}
            values={initValues}
            handleChange={handleChange}
          />
        )}
        {formField.type === "checkbox" && (
          <Checkbox
            key={keyId}
            {...formField}
            values={initValues}
            handleChange={handleChange}
          />
        )}
      </div>
    );
  };
  return (
    <>
      {loading && <Loader size="medium" />}
      {!loading && (
        <div className="pl-10">
          <ToastContainer />
          <DataTypeDetailsHeader
            importStatus={initValues?.status}
            importType="Edit"
          />
          <div className="font-open-sans font-normal not-italic">
            <form
              className="bg-white rounded pt-[25px] pb-8 mb-4 data-type-form"
              onSubmit={handleSubmit}
            >
              {[...formData].map((formField: any, keyId: number) => {
                return renderFormFields(formField, keyId);
              })}
              <DataTypeFieldsComponent
                data={initValues!}
                changeInitValues={changeInitValues}
                handleChange={handleChange}
                updateFormError={updateFormError}
              />
              <button id="submitForm" type="submit" style={{ display: "none" }}>
                Submit
              </button>
            </form>
          </div>
          <DataTypeDetailsFooter
            buttonFunctionality={disableSavePreviewbutton}
            saveFormData={saveFormData}
            setSaveAndClose={setSaveAndClose}
          />
        </div>
      )}
    </>
  );
};
