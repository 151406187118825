import { Space } from "antd";
import { useContext, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { createGrouping } from "../../../api";
import Button from "../../../components/Button";
import Drawer from "../../../components/Drawer";
import Loader from "../../../components/Loader";
import "react-toastify/dist/ReactToastify.css";
import drawerContext from "../../../utils/drawerContext";

type CreateGroupingProps = {
  setStates: () => void;
};

export const CreateGrouping = ({ setStates }: CreateGroupingProps) => {
  const [Grouping, setGrouping] = useState("");
  const [Publishing, setPublishing] = useState(false);
  const drawerCxt = useContext(drawerContext);

  const groupingCreatedAlert = () =>
    toast.success("Grouping Created", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });

  const groupingCreationFailedAlert = () =>
    toast.error("Grouping Creation failed", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });

  const handleChange = (event: any) => {
    setGrouping(event.target.value);
  };
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const groupingInput = {
      name: Grouping.replace(/^\s+|\s+$/gm, ""),
    };
    try {
      setPublishing(true);
      const res = await createGrouping(groupingInput);
      if (res.status == 201) {
        drawerCxt.HideDrawer();
        groupingCreatedAlert();
        setStates();
      }
      setPublishing(false);
    } catch (err) {
      drawerCxt.HideDrawer();
      groupingCreationFailedAlert();
      console.log(err);
    }
    setGrouping("");
  };

  return (
    <div data-test-id="create-grouping">
      <ToastContainer />
      <Button
        data-test-id="button"
        className="border-solid border-blue-color border text-blue-color bg-white hover:bg-hover-color hover:border-hover-color font-open-sans font-normal rounded text-base px-4 py-2 text-center dark:hover:border-hover-color dark:bg-white"
        onClick={() => drawerCxt.ShowCreateDrawer()}
      >
        Create New Grouping
      </Button>
      <Drawer
        autoFocus={false}
        destroyOnClose
        extra={
          <Space>
            <Button
              data-test-id="button"
              form="my-form"
              disabled={!Grouping}
              className=" bg-gradient-to-b from-btn-blue-color to-dark-blue-color text-white text-base font-open-sans font-normal py-4 px-8 rounded hover:from-dark-blue-color hover:to-dark-blue-color"
            >
              Publish
            </Button>
          </Space>
        }
      >
        <>
          {Publishing && <Loader size="medium" />}
          {!Publishing && (
            <div>
              <div className="text-3xl font-open-sans font-normal ">
                Create New
              </div>
              <form id="my-form" onSubmit={handleSubmit}>
                <div className="flex flex-wrap -mx-3 mb-6 ">
                  <div className="w-full px-3 py-5">
                    <label className="block tracking-wide font-open-sans text-light-black-color text-small-custom-1 font-normal mb-1">
                      Grouping
                    </label>
                    <input
                      className="appearance-none border-b font-open-sans font-normal text-base border-gray-600 block w-full bg-gray-200 text-inputText-color placeholder-placeholderText-color py-3 px-4 mb-3 leading-tight focus:outline-none rounded-t-md focus:border-gray-500"
                      type="text"
                      maxLength={250}
                      value={Grouping}
                      placeholder="Enter Grouping Name"
                      onChange={handleChange}
                      autoFocus={true}
                    />
                  </div>
                </div>
              </form>
            </div>
          )}
        </>
      </Drawer>
    </div>
  );
};
