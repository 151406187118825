import Icon from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { LeftArrow } from "../../../utils/icons/svgIcons";

type detailsHeaderProps = {
  importStatus: string | undefined;
  importType: string;
};

const ArrowLeftIcon = (props: any) => (
  <Icon
    tabIndex={1}
    component={LeftArrow}
    {...props}
    id="create-back-arrow-icon"
  />
);
export const DataTypeDetailsHeader = ({
  importStatus,
  importType,
}: detailsHeaderProps) => {
  const navigate = useNavigate();
  return (
    <>
      <button
        onClick={() => navigate(-1)}
        type="button"
        className="flex items-baseline -ml-3 mt-5"
      >
        <ArrowLeftIcon className="h-2 w-1" />
        <div className="border-b-2 border-[#C4C4C4] text-flat-gray-color text-small-custom-3 font-semibold ml-2">
          Back
        </div>
      </button>
      <h1 className="text-2xl font-bold mt-4 text-flat-gray-color">
        {importType}
      </h1>
      <h2 className="text-xl font-normal mt-10 mb-5">Details</h2>
      <p className="font-bold text-small-custom-1 text-status-label-color">
        Status
      </p>
      <span
        id={
          importStatus === "Published"
            ? "grouping-badge-published"
            : importStatus === "UnPublished"
            ? "grouping-badge-unpublished"
            : importStatus === "Draft"
            ? "grouping-badge-draft"
            : "grouping-badge-test"
        }
      >
        {importStatus}
      </span>
    </>
  );
};
