import "./AddFieldValidation.css";
import Icon from "@ant-design/icons";
import { Space } from "antd";
import React, { useEffect, useState } from "react";
import { Button } from "../../../../components";
import Label from "../../../../components/Forms/Label";
import Radio from "../../../../components/Forms/Radio";
import ValidationTypeDropdown from "../../../../components/Forms/ValidationTypeDropDown";
import ValidationDrawer from "../../../../components/ValidationDrawer/ValidationDrawer";
import { createDataType, dataTypeFields } from "../../../../types";
import * as Constants from "../../../../utils/constants";
import { CloseX } from "../../../../utils/icons/svgIcons";
import { errorAlert } from "../../../../utils/toast/Toast";
import { validateUniqueAttributes } from "../../common";
import {
  dateFormats,
  validationFormData,
  validatioTypeDropDownValue,
} from "../constants";

type validationFieldsProps = {
  fieldData: dataTypeFields;
  changeInitValues: (updateInitValues: createDataType) => void;
  initialValues: createDataType;
  currentFieldIndex: number;
  drawerStatus: boolean;
  closeDrawer: Function;
};

export const AddFieldValidation = ({
  fieldData,
  changeInitValues,
  initialValues,
  drawerStatus,
  closeDrawer,
  currentFieldIndex,
}: validationFieldsProps) => {
  const [inputFieldValues, setInputFieldValues] =
    useState<dataTypeFields>(fieldData);
  const [showValidationTypeFields, setShowValidationTypeFields] =
    useState<string>("");
  const [validationTypeOptions, setValidationTypeOptions] = useState(
    validatioTypeDropDownValue[inputFieldValues.inputType]
  );
  const [acceptableValues, setAcceptableValues] = useState([
    { navianceValue: "", fileValue: "" },
  ]);
  const [range, setRange] = useState({ min: -1, max: -1 });
  let { name: fieldName, inputType, columnInFile } = inputFieldValues;
  let rangeMin =
    inputFieldValues?.range?.min === -1
      ? undefined
      : inputFieldValues?.range?.min;
  let rangeMax =
    inputFieldValues?.range?.max === -1
      ? undefined
      : inputFieldValues?.range?.max!;
  let maxCharacter: number | undefined;
  maxCharacter =
    maxCharacter === -1 ? undefined : inputFieldValues?.maxCharacter;

  useEffect(() => {
    setInputFieldValues(fieldData);
    let validationOptions: { label: string; value: string } =
      inputFieldValues.inputType &&
      validatioTypeDropDownValue[inputFieldValues.inputType];
    setValidationTypeOptions(validationOptions);
    if (fieldData && fieldData.validationType) {
      fieldData.validationType
        ? setShowValidationTypeFields(fieldData.validationType)
        : setShowValidationTypeFields("");
    }
    if (fieldData && fieldData.acceptableValues) {
      let EditAcceptableValues = Object.keys(fieldData?.acceptableValues).length
        ? Object.keys(fieldData.acceptableValues).map((key: any) => ({
            navianceValue: key,
            fileValue: fieldData?.acceptableValues![key],
          }))
        : acceptableValues;
      setAcceptableValues(EditAcceptableValues as any);
    }
    window.addEventListener("keydown", handleEsc);
    return () => {
      setShowValidationTypeFields("");
      window.removeEventListener("keydown", handleEsc);
    };
  }, [drawerStatus]);

  const handlesubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let emptyAcceptableValues = Object.values(acceptableValues).every(
      (val) => val.navianceValue !== "" && val.fileValue !== ""
    );
    if (emptyAcceptableValues) {
      const acceptableKeyValue: { [key: string]: string | Number } = {};
      acceptableValues.forEach((data) => {
        if (data.navianceValue && data.navianceValue)
          acceptableKeyValue[data.navianceValue] = data.fileValue;
      });
      inputFieldValues.acceptableValues = acceptableKeyValue;
    }

    if (inputFieldValues.isUnique) {
      try {
        validateUniqueAttributes([inputFieldValues]);
      } catch (err: any) {
        errorAlert(err.message);
        return;
      }
    }

    setInputFieldValues(inputFieldValues);
    let FieldValues = initialValues.fields;
    FieldValues[currentFieldIndex] = inputFieldValues;
    changeInitValues({
      ...initialValues,
      ...{ fields: FieldValues },
    });
    closeDrawer();
  };
  const typeConvertion = (value: any) => {
    if (value && typeof value === "string") {
      if (value.toLowerCase() === Constants.TRUE) value = true;
      else if (value.toLowerCase() === Constants.FALSE) value = false;
      else {
        value = +value ? +value : value;
      }
      return value;
    }
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    i?: number
  ) => {
    const { target } = event;
    let { name, value }: { name: string; value: number | string | boolean } =
      target as HTMLInputElement;
    if (name === Constants.VALIDATION_TYPE) {
      setAcceptableValues([{ navianceValue: "", fileValue: "" }]);
      let newFieldValues = { ...inputFieldValues };
      if (value === Constants.DATE) {
        newFieldValues.dateFormat = Constants.DEFAULT_DATE_FORMAT;
      }
      delete newFieldValues.maxCharacter;
      delete newFieldValues.range;
      newFieldValues.validationType = value;
      setInputFieldValues(newFieldValues);
      setShowValidationTypeFields(value);
    } else if (
      name === Constants.NAVIANCE_VALUE ||
      name === Constants.FILE_VALUË
    ) {
      let newFormValues: { navianceValue: string; fileValue: string }[] = [
        ...acceptableValues,
      ];
      (newFormValues as any)[i as number][name] = value;
      setAcceptableValues(newFormValues);
    } else if (name === Constants.MIN || name === Constants.MAX) {
      let rangeValues: typeof range = range;
      rangeValues[name] = +value;
      setRange(rangeValues);
      let previousRange = inputFieldValues.range ?? range;
      setInputFieldValues((data) => ({
        ...data,
        range: { ...previousRange, [name]: +value },
      }));
    } else {
      value = typeConvertion(value);
      setInputFieldValues((data) => ({ ...data, [name]: value }));
    }
  };

  const getAcceptableValueFields = () => {
    const CloseIcon = (prop: any) => (
      <Icon tabIndex={1} component={CloseX} {...prop} />
    );

    return (
      <div className="flex flex-col justify-center">
        <p className="text-small-custom-1 font-open-sans font-bold text-status-label-color mb-1.5">
          Acceptable Values - Naviance Match
        </p>
        <div className="flex mb-1">
          <p className="w-52 mr-6"> Naviance Value</p>
          <p className="w-52"> Value in file</p>
        </div>
        {acceptableValues.map((ele, index) => (
          <div className="flex fles-wrap flex-row w-[880px]" key={index}>
            <div className="mr-3">
              <input
                className={`appearance-none border-b font-open-sans font-normal text-small-custom-3 border-gray-600 block w-full bg-gray-200 text-inputText-color placeholder-placeholderText-color py-3 px-4 mb-3 leading-tight focus:outline-none rounded-t-md focus:border-gray-500 h-10`}
                type={
                  inputFieldValues?.inputType == "Number" ? "number" : "text"
                }
                name="navianceValue"
                required={true}
                autoFocus={true}
                value={ele.navianceValue}
                onChange={(val) => handleChange(val, index)}
              />
            </div>
            <div className="mr-3">
              <input
                className={`appearance-none border-b font-open-sans font-normal text-small-custom-3 border-gray-600 block w-full bg-gray-200 text-inputText-color placeholder-placeholderText-color py-3 px-4 mb-3 leading-tight focus:outline-none rounded-t-md focus:border-gray-500 h-10`}
                type={
                  inputFieldValues?.inputType == "Number" ? "number" : "text"
                }
                name="fileValue"
                value={ele.fileValue}
                required={true}
                onChange={(val) => handleChange(val, index)}
              />
            </div>
            {index ? (
              <div className="flex justify-center items-center">
                <CloseIcon onClick={() => removeAcceptableValue(index)} />
              </div>
            ) : null}
          </div>
        ))}
        <div className="ml-32 mt-6">
          <Button
            data-test-id="button"
            type="button"
            className="border-solid border-blue-color border text-blue-color bg-white hover:bg-hover-color hover:border-hover-color font-open-sans font-normal rounded text-base px-4 py-2 text-center dark:hover:border-hover-color dark:bg-white flex flex-col justify-center items-center w-40 h-8"
            onClick={addFormFields}
          >
            Add New Value
          </Button>
        </div>
      </div>
    );
  };

  const getMaxCharacterFields = () => (
    <div>
      <Label label={"Maximum Number of Characters Allowed"} fontType={""} />
      <input
        className={`appearance-none border-b font-open-sans font-normal text-small-custom-3 border-gray-600 block w-full bg-gray-200 text-inputText-color placeholder-placeholderText-color py-3 px-4 mb-3 leading-tight focus:outline-none rounded-t-md focus:border-gray-500`}
        type="number"
        min={1}
        required={true}
        name={"maxCharacter"}
        placeholder="Enter Maximum Number of Characters"
        defaultValue={maxCharacter}
        autoFocus={true}
        onChange={handleChange}
      />
    </div>
  );

  const getValueRangeFields = () => (
    <>
      <div>
        <Label label={"Minimum"} fontType={""} />
        <input
          className={`appearance-none border-b font-open-sans font-normal text-small-custom-3 border-gray-600 block w-full bg-gray-200 text-inputText-color placeholder-placeholderText-color py-3 px-4 mb-3 leading-tight focus:outline-none rounded-t-md focus:border-gray-500`}
          type="number"
          name={"min"}
          step={0.001}
          required={true}
          min={0}
          max={99998}
          placeholder="Enter Minimum Value"
          autoFocus={true}
          defaultValue={rangeMin}
          onChange={handleChange}
        />
      </div>
      <div>
        <Label label={"Maximum"} fontType={""} />
        <input
          className={`appearance-none border-b font-open-sans font-normal text-small-custom-3 border-gray-600 block w-full bg-gray-200 text-inputText-color placeholder-placeholderText-color py-3 px-4 mb-3 focus:outline-none rounded-t-md focus:border-gray-500`}
          type="number"
          name={"max"}
          required={true}
          step={0.001}
          min={
            inputFieldValues.range?.min &&
            Number(inputFieldValues.range?.min) > 0
              ? Number(inputFieldValues.range?.min) + 0.001
              : 0.001
          }
          max={99999}
          maxLength={250}
          defaultValue={rangeMax}
          placeholder="Enter Maximum Value"
          onChange={handleChange}
        />
      </div>
    </>
  );

  const getDateFields = () => (
    <div>
      <ValidationTypeDropdown
        keyId={0}
        label={"Date Format"}
        name={"dateFormat"}
        values={inputFieldValues}
        options={dateFormats}
        handleChange={handleChange}
        type={"dropdown"}
      />
    </div>
  );

  const addFormFields = (
    event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent
  ) => {
    event.preventDefault();
    setAcceptableValues([
      ...acceptableValues,
      { navianceValue: "", fileValue: "" },
    ]);
  };
  const removeAcceptableValue = (i: number) => {
    const newFormValues = [...acceptableValues];
    newFormValues.splice(i, 1);
    setAcceptableValues(newFormValues);
  };

  const saveData = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const submitBtn1 = document.getElementById("validationForm");
    submitBtn1?.click();
  };
  const handleEsc = (event: any) => {
    if (event.key === Constants.ESCAPE) {
      closeDrawer();
      event?.target?.blur();
    } else handleChange;
  };

  const renderFormFields = (formField: any, keyId: number) => {
    return (
      <div
        className="w-full px-3 mb-5 pt-6 border-[#BFBEBF] border-t-[1px]"
        key={keyId}
      >
        {formField.type === Constants.RADIO && (
          <Radio
            key={keyId}
            {...formField}
            values={inputFieldValues}
            handleChange={handleChange}
          />
        )}
      </div>
    );
  };
  return (
    <>
      <ValidationDrawer
        className="drawer-padding"
        autoFocus={false}
        visible={drawerStatus}
        onClose={closeDrawer}
        destroyOnClose
        extra={
          <Space>
            <Button
              data-test-id="button"
              className=" bg-gradient-to-b from-btn-blue-color to-dark-blue-color text-white text-base font-open-sans font-normal py-4 px-8 rounded hover:from-dark-blue-color hover:to-dark-blue-color"
              onClick={saveData}
            >
              Save
            </Button>
          </Space>
        }
      >
        <div data-style={"padding-left:12px"}>
          <div className="text-3xl mb-5 font-open-sans font-normal">
            Validation
          </div>
          <form
            name="validation Form"
            className="w-full"
            onSubmit={handlesubmit}
          >
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3 mb-5">
                <Label label="Field Name" fontType="" />
                <Label label={fieldName} fontType="bold" />
              </div>
              <div className="w-full px-3 mb-5">
                <Label label="Field Input Type" fontType={""} />
                <Label label={inputType} fontType="bold" />
              </div>
              {initialValues.format &&
                initialValues.format.toLowerCase() === Constants.OFFICIAL && (
                  <div className="w-full px-3 mb-6">
                    <Label label="Column in File" fontType={""} />
                    <Label label={columnInFile!} fontType="bold" />
                  </div>
                )}
              {validationFormData.map((formField: any, keyId: number) => {
                return renderFormFields(formField, keyId);
              })}
              <div className="px-3 mb-6">
                <ValidationTypeDropdown
                  keyId={0}
                  label={"Field Validation Type"}
                  name={"validationType"}
                  values={fieldData}
                  options={validationTypeOptions}
                  handleChange={handleChange}
                  type={"fieldTypeDropdown"}
                />
              </div>
              <div className="w-[450px] px-3 mb-8">
                {showValidationTypeFields === Constants.ACCEPTABLE_VALUES &&
                  getAcceptableValueFields()}
                {showValidationTypeFields === Constants.MAX_CHARACTERS &&
                  getMaxCharacterFields()}
                {showValidationTypeFields === Constants.RANGE &&
                  getValueRangeFields()}
                {showValidationTypeFields === Constants.DATE && getDateFields()}
              </div>
            </div>
            <button
              id="validationForm"
              type="submit"
              style={{ display: "none" }}
            >
              Submit
            </button>
          </form>
        </div>
      </ValidationDrawer>
    </>
  );
};
