import { useState, useEffect } from "react";
import { getGrouping } from "../../../api";
import { groupingType } from "../../../types";
import drawerContext from "../../../utils/drawerContext";
import { CreateGrouping } from "../create";
import { EditGrouping } from "../edit";
import { ListGrouping } from "./ListGrouping";

export const ListGroupings = () => {
  const [grouping, setGrouping] = useState<groupingType[]>([]);
  const [loading, setLoading] = useState(true);
  const [editDrawerVisible, setEditDrawerVisible] = useState<boolean>(false);
  const [createDrawerVisible, setCreateDrawerVisible] =
    useState<boolean>(false);
  const [rowData, setRowData] = useState<any>();

  const showCreateDrawer = () => {
    !editDrawerVisible && setCreateDrawerVisible(true);
  };

  const showEditDrawer = () => {
    !createDrawerVisible && setEditDrawerVisible(true);
  };

  const hideDrawer = () => {
    createDrawerVisible && setCreateDrawerVisible(false);
    editDrawerVisible && setEditDrawerVisible(false);
  };

  const getGroupingData = () => {
    getGrouping()
      .then((res) => {
        setGrouping(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error fetching grouping data: ", error);
        setLoading(false);
      });
  };

  const setStates = () => {
    setLoading(true);
    getGroupingData();
  };

  const drawerCtx = {
    DrawerStatus:
      (createDrawerVisible && !editDrawerVisible) ||
      (!createDrawerVisible && editDrawerVisible),
    ShowEditDrawer: showEditDrawer,
    HideDrawer: hideDrawer,
    GetGroupingData: setStates,
    ShowCreateDrawer: showCreateDrawer,
    createDrawer: createDrawerVisible,
  };

  useEffect(() => {
    getGroupingData();
  }, []);

  return (
    <drawerContext.Provider value={drawerCtx}>
      <div className="px-10 font-open-sans" data-test-id="groupings-list">
        <div
          className="flex justify-between bg-white"
          style={{ paddingTop: "41px" }}
        >
          <div className="text-2xl font-bold">Manage Import Types</div>
          <CreateGrouping setStates={setStates} />
          {editDrawerVisible && <EditGrouping rowData={rowData} />}
        </div>
        <div className="flex flex-col mt-[18px]">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <ListGrouping
                  grouping={grouping}
                  loading={loading}
                  setRowData={setRowData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </drawerContext.Provider>
  );
};
