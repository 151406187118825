export const VALIDATION_TYPE = "validationType";
export const DATE = "Date";
export const TEXT = "Text";
export const DEFAULT_DATE_FORMAT = "MM/DD/YYYY";
export const NAVIANCE_VALUE = "navianceValue";
export const FILE_VALUË = "fileValue";
export const MIN = "min";
export const MAX = "max";
export const ESCAPE = "Escape";
export const ACCEPTABLE_VALUES = "AcceptableValues";
export const MAX_CHARACTERS = "MaxCharacters";
export const RANGE = "Range";
export const OFFICIAL = "official";
export const TRUE = "true";
export const FALSE = "false";
export const RADIO = "radio";
