type CheckboxProps = {
  keyId: number;
  label: string;
  name: string;
  type: string;
  options: any[];
  handleChange: (e: any) => void;
  values: any;
  disabled: boolean;
  children: any;
};
const Checkbox = ({
  keyId,
  label,
  name,
  type,
  options,
  handleChange,
  values,
  disabled,
}: CheckboxProps) => {
  return (
    <div key={keyId}>
      <div className="text-small-custom-3 text-light-black-color">{label}</div>
      <div>
        {options.map((option, index) => (
          <div className="flex items-center" key={index}>
            <input
              type={type}
              name={name}
              onChange={handleChange}
              value={option.value}
              checked={values[name]}
              id={keyId + name + option.label}
              disabled={disabled}
            />
            <label
              htmlFor={keyId + name + option.label}
              className="mr-8 ml-2 text-labelText-color text-small-custom-3"
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Checkbox;
