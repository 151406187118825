import { apiClient } from "../utils";

export const getDataTypes = async (groupingId: string) => {
  const dataTypes = await apiClient({
    url: `/grouping/${groupingId}/datatype`,
    method: "GET",
  });
  return dataTypes;
};

export const deleteDataTypes = async (groupingId: string, id: string) => {
  const dataTypes = await apiClient({
    url: `/grouping/${groupingId}/datatype/${id}`,
    method: "DELETE",
  });
  return dataTypes;
};

export const updateDataType = async (
  updatedDataType: any,
  groupingId: string,
  id: string
) => {
  const dataType = await apiClient({
    url: `/grouping/${groupingId}/datatype/${id}`,
    method: "PATCH",
    data: updatedDataType,
  });
  return dataType;
};

export const createNewDataType = async (
  dataTypeObject: any,
  groupingId: string
) => {
  const dataType = await apiClient({
    url: `/grouping/${groupingId}/datatype`,
    method: "POST",
    data: dataTypeObject,
  });
  return dataType;
};

export const getDataType = async (dataTypeId: string) => {
  const dataType = await apiClient({
    url: `/datatype/${dataTypeId}/detail`,
    method: "GET",
  });
  return dataType;
};

export const CheckDuplicateDataType = async (name: string) => {
  const dataType = await apiClient({
    url: "/datatype/availability-check",
    method: "POST",
    data: { name },
  });
  return dataType;
};
