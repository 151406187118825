import { dataTypeFields } from "../../../types";

export const required = "required";
export const statesData = [
  {
    label: "All Clients",
    value: "ALL_CLIENTS",
  },
  {
    label: "Alabama",
    value: "AL",
  },
  {
    label: "Alaska",
    value: "AK",
  },
  {
    label: "Arizona",
    value: "AZ",
  },
  {
    label: "Arkansas",
    value: "AR",
  },
  {
    label: "California",
    value: "CA",
  },
  {
    label: "Colorado",
    value: "CO",
  },
  {
    label: "Connecticut",
    value: "CT",
  },
  {
    label: "Delaware",
    value: "DE",
  },
  {
    label: "Florida",
    value: "FL",
  },
  {
    label: "Georgia",
    value: "GA",
  },
  {
    label: "Hawaii",
    value: "HI",
  },
  {
    label: "Idaho",
    value: "ID",
  },
  {
    label: "Illinois",
    value: "IL",
  },
  {
    label: "Indiana",
    value: "IN",
  },
  {
    label: "Iowa",
    value: "IA",
  },
  {
    label: "Kansas",
    value: "KS",
  },
  {
    label: "Kentucky",
    value: "KY",
  },
  {
    label: "Louisiana",
    value: "LA",
  },
  {
    label: "Maine",
    value: "ME",
  },
  {
    label: "Maryland",
    value: "MD",
  },
  {
    label: "Massachusetts",
    value: "MA",
  },
  {
    label: "Michigan",
    value: "MI",
  },
  {
    label: "Minnesota",
    value: "MN",
  },
  {
    label: "Mississippi",
    value: "MS",
  },
  {
    label: "Missouri",
    value: "MO",
  },
  {
    label: "Montana",
    value: "MT",
  },
  {
    label: "Nebraska",
    value: "NE",
  },
  {
    label: "Nevada",
    value: "NV",
  },
  {
    label: "New Hampshire",
    value: "NH",
  },
  {
    label: "New Jersey",
    value: "NJ",
  },
  {
    label: "New Mexico",
    value: "NM",
  },
  {
    label: "New York",
    value: "NY",
  },
  {
    label: "North Carolina",
    value: "NC",
  },
  {
    label: "North Dakota",
    value: "ND",
  },
  {
    label: "Ohio",
    value: "OH",
  },
  {
    label: "Oklahoma",
    value: "OK",
  },
  {
    label: "Oregon",
    value: "OR",
  },
  {
    label: "Pennsylvania",
    value: "PA",
  },
  {
    label: "Rhode Island",
    value: "RI",
  },
  {
    label: "South Carolina",
    value: "SC",
  },
  {
    label: "South Dakota",
    value: "SD",
  },
  {
    label: "Tennessee",
    value: "TN",
  },
  {
    label: "Texas",
    value: "TX",
  },
  {
    label: "Utah",
    value: "UT",
  },
  {
    label: "Vermont",
    value: "VT",
  },
  {
    label: "Virginia",
    value: "VA",
  },
  {
    label: "Washington",
    value: "WA",
  },
  {
    label: "West Virginia",
    value: "WV",
  },
  {
    label: "Wisconsin",
    value: "WI",
  },
  {
    label: "Wyoming",
    value: "WY",
  },
];

export const validatioTypeDropDownValue: any = {
  Text: [
    {
      label: "Acceptable Values",
      value: "AcceptableValues",
    },
    {
      label: "Maximum Number of Characters Allowed",
      value: "MaxCharacters",
    },
  ],
  Number: [
    {
      label: "Range",
      value: "Range",
    },
    {
      label: "Acceptable Values",
      value: "AcceptableValues",
    },
  ],
  Date: [
    {
      label: "Date",
      value: "Date",
    },
  ],
};

export const dateFormats = [
  {
    label: "MM/DD/YYYY",
    value: "MM/DD/YYYY",
  },
  {
    label: "MM-DD-YYYY",
    value: "MM-DD-YYYY",
  },
  {
    label: "MMDDYYYY",
    value: "MMDDYYYY",
  },
  {
    label: "MM/DD/YY",
    value: "MM/DD/YY",
  },
  {
    label: "MMDDYY",
    value: "MMDDYY",
  },
  {
    label: "MM/YYYY",
    value: "MM/YYYY",
  },
  {
    label: "MM-YYYY",
    value: "MM-YYYY",
  },
  {
    label: "MMYY",
    value: "MMYY",
  },
  {
    label: "DD/MM/YYYY",
    value: "DD/MM/YYYY",
  },
  {
    label: "DD-MM-YYYY",
    value: "DD-MM-YYYY",
  },
  {
    label: "DDMMYYYY",
    value: "DDMMYYYY",
  },
  {
    label: "YYYY/MM/DD",
    value: "YYYY/MM/DD",
  },
  {
    label: "YYYY/DD/MM",
    value: "YYYY/DD/MM",
  },
  {
    label: "YYYY-MM-DD",
    value: "YYYY-MM-DD",
  },
  {
    label: "YYYY-DD-MM",
    value: "YYYY-DD-MM",
  },
  {
    label: "YYYYMMDD",
    value: "YYYYMMDD",
  },
  {
    label: "YYYYDDMM",
    value: "YYYYDDMM",
  },
  {
    label: "YYYY/MM",
    value: "YYYY/MM",
  },
  {
    label: "YYYY-MM",
    value: "YYYY-MM",
  },
  {
    label: "YYMMDD",
    value: "YYMMDD",
  },
  {
    label: "YYMM",
    value: "YYMM",
  },
];

export const validationFormData = [
  {
    type: "radio",
    name: "required",
    label: "Required Field",
    options: [
      { label: "Yes", value: true },
      { label: "No", value: false, checked: true },
    ],
    isHorizontal: true,
  },
];

const fieldInputData = [
  {
    label: "Text",
    value: "Text",
  },
  {
    label: "Number",
    value: "Number",
  },
  {
    label: "Date",
    value: "Date",
  },
];

export const initialValues = {
  name: "",
  format: "Official",
  availability: "ALL_CLIENTS",
  url: "",
  keyGroup: "A",
  displayScores: true,
  fields: [
    {
      name: "",
      inputType: "Text",
      required: false,
      columnInFile: "",
      isScoreKey: true,
      isUnique: false,
    },
  ],
};

export const formData = [
  {
    type: "radio",
    name: "format",
    label: "File Format",
    options: [
      { label: "Official format", value: "Official", checked: true },
      { label: "Custom Format", value: "Custom" },
    ],
    isHorizontal: true,
  },
  {
    type: "text",
    name: "name",
    label: "Name",
    placeholder: "Name",
    required: true,
    maxLength: 180,
  },
  {
    type: "dropdown",
    name: "availability",
    label: "Availability",
    options: statesData,
  },
  {
    type: "text",
    name: "url",
    label: "URL for downloadable template (optional)",
    placeholder: "Enter URL",
    maxLength: 300,
  },
  {
    type: "checkbox",
    name: "displayScores",
    label: "",
    options: [
      { label: "Display scores in Student Folder", value: "displayScores" },
    ],
  },
];

export const scoreKey = [
  {
    type: "checkbox",
    name: "isScoreKey",
    label: "",
    options: [
      {
        label: "Field to be displayed in Student Folder",
        value: "Field to be displayed",
      },
    ],
  },
];

export const uniqueKey = [
  {
    type: "checkbox",
    name: "isUnique",
    label: "",
    options: [
      {
        label: "Mark as sub test type",
        value: "Mark as sub test type",
      },
    ],
    isHorizontal: true,
  },
];
export const inputFormFields = [
  {
    type: "text",
    name: "name",
    label: "Field Name",
    required: true,
    maxLength: 180,
  },
  {
    type: "dropdown",
    name: "inputType",
    label: "Field Input Type",
    options: fieldInputData,
    required: true,
  },

  {
    type: "text",
    label: "Column in File",
    name: "columnInFile",
    placeholder: " ",
    required: true,
    maxLength: 180,
  },
  {
    type: "textarea",
    label: "Field Description",
    name: "description",
    maxLength: 180,
  },
];

export const defaultUniqueFieldsBC = [
  {
    name: "State Student ID",
    inputType: "Text",
    required: false,
    columnInFile: "",
    description: "",
    sortOrder: 1,
    nonEditable: true,
  },
  {
    name: "Student ID",
    inputType: "Text",
    required: false,
    columnInFile: "",
    description: "",
    sortOrder: 2,
    nonEditable: true,
  },
  {
    name: "Last Name",
    inputType: "Text",
    required: false,
    columnInFile: "",
    description: "",
    sortOrder: 3,
    nonEditable: true,
    validationType: "MaxCharacters",
    maxCharacter: 50,
  },
];

export const defaultUniqueFields = [
  {
    name: "First Name",
    inputType: "Text",
    required: false,
    columnInFile: "",
    description: "",
    sortOrder: 1,
    nonEditable: true,
    validationType: "MaxCharacters",
    maxCharacter: 50,
    isUnique: false,
  },
  {
    name: "Last Name",
    inputType: "Text",
    required: false,
    columnInFile: "",
    description: "",
    sortOrder: 2,
    nonEditable: true,
    validationType: "MaxCharacters",
    maxCharacter: 50,
    isUnique: false,
  },
  {
    name: "Birthdate",
    inputType: "Date",
    required: false,
    columnInFile: "",
    description: "",
    sortOrder: 3,
    nonEditable: true,
    validationType: "Date",
    dateFormat: "MM/DD/YYYY",
    isUnique: false,
  },
  {
    name: "State Student ID",
    inputType: "Text",
    required: false,
    columnInFile: "",
    description: "",
    sortOrder: 4,
    nonEditable: true,
    isUnique: false,
  },
  {
    name: "Student ID",
    inputType: "Text",
    required: false,
    columnInFile: "",
    description: "",
    sortOrder: 5,
    nonEditable: true,
    isUnique: false,
  },
];

export const defaultUniqueFieldsName = [
  "First Name",
  "Last Name",
  "Birthdate",
  "State Student ID",
  "Student ID",
];

export const defaultOfficialInputFields: dataTypeFields = {
  name: "",
  inputType: "Text",
  required: false,
  columnInFile: "",
  sortOrder: 6,
  isScoreKey: true,
  isUnique: false,
};

export const defaultCustomInputFields: dataTypeFields = {
  name: "",
  inputType: "Text",
  required: false,
  sortOrder: 6,
  description: "",
  isUnique: false,
};

export const officalUniqueIdentifiers: { [key: string]: Array<string> } = {
  A: ["First Name", "Last Name", "Birthdate"],
  B: ["Student ID", "Last Name"],
  C: ["State Student ID", "Last Name"],
};

export const officialUniqueIdentifierFormField = {
  type: "radio",
  name: "keyGroup",
  label: "",
  options: [
    { label: "First Name, Last Name, and Birthdate", value: "A" },
    { label: "Student ID and Last Name", value: "B" },
    { label: "State Student ID and Last Name", value: "C" },
  ],
  isHorizontal: false,
};
