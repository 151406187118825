import Icon from "@ant-design/icons";
import { useEffect, useState } from "react";
import Checkbox from "../../../components/Forms/Checkbox";
import Dropdown from "../../../components/Forms/Dropdown";
import TextAreaField from "../../../components/Forms/TextAreaField";
import TextField from "../../../components/Forms/TextField";
import {
  createDataType,
  dataTypeFields,
  inputFieldProps,
} from "../../../types";
import { CloseIcon } from "../../../utils/icons/svgIcons";
import { AddFieldValidation } from "./AddFieldValidation/AddFieldValidation";
import {
  defaultCustomInputFields,
  defaultOfficialInputFields,
  inputFormFields,
  required,
} from "./constants";

type inputFieldsProps = {
  data: createDataType;
  changeInitValues: (updateInitValues: createDataType) => void;
  updateFormError: (errStatus: any) => void;
};

const CloseCrossIcon = (props: any) => (
  <Icon component={CloseIcon} {...props} id="create-close-icon" />
);

export const InputFieldsComponent = ({
  data,
  changeInitValues,
  updateFormError,
}: inputFieldsProps) => {
  const [inputFieldsValues, setInputFieldsValues] = useState<
    Array<dataTypeFields>
  >(data.fields);
  const [currentFieldIndex, setCurrentFieldIndex] = useState<any>();
  const [openDrawer, setOpenDrawer] = useState(false);
  const onClose = () => {
    setOpenDrawer(false);
  };
  const dataTypeFormat = data.format;
  const allColumnValues = inputFieldsValues.map(
    (inputValue: dataTypeFields) => {
      let a = (inputValue?.columnInFile ?? "").toUpperCase();
      return a;
    }
  );
  const allFieldNames = inputFieldsValues.map((inputValue: dataTypeFields) => {
    return inputValue.name;
  });

  const allSortOrderValues = inputFieldsValues.map(
    (inputValue: dataTypeFields) => {
      return inputValue.sortOrder;
    }
  );
  const findHighestSortOrderValue = (sortOrderValues: any) => {
    return Math.max(...sortOrderValues);
  };

  const resetPreviousInputFieldValidationValues = (
    updatedInputFieldsValues: dataTypeFields[],
    step: number,
    value: string
  ) => {
    (updatedInputFieldsValues as any)[step].required = false;
    if (dataTypeFormat === "Official") {
      (updatedInputFieldsValues as any)[step].isScoreKey = true;
      (updatedInputFieldsValues as any)[step].isUnique = false;
    }
    delete (updatedInputFieldsValues as any)[step]?.range;
    delete (updatedInputFieldsValues as any)[step]?.dateFormat;
    delete (updatedInputFieldsValues as any)[step]?.maxCharacter;
    delete (updatedInputFieldsValues as any)[step]?.validationType;
    if (
      updatedInputFieldsValues[step]?.inputType !== "Date" &&
      value === "Date"
    ) {
      updatedInputFieldsValues[step].validationType = "Date";
      updatedInputFieldsValues[step].dateFormat = "MM/DD/YYYY";
    }
  };

  const checkForUniqueField = (
    event: any,
    { name, step, value, type }: any,
    updatedInputFieldsValues: Array<dataTypeFields>
  ) => {
    if (type === "checkbox") {
      if (name === "isUnique") {
        updatedInputFieldsValues = updatedInputFieldsValues.map((field) => {
          if (field.isUnique) {
            field.isUnique = false;
            field.required = false;
          }
          return field;
        });
        (updatedInputFieldsValues as any)[step][required] =
          event.currentTarget.checked;
      }
      value = event.currentTarget.checked ? true : false;
    }
    return { value, updatedInputFieldsValues };
  };

  const handleChange = (event: any, position?: number) => {
    const { target } = event;
    const { name, type } = target;
    let step = type === "checkbox" ? position : target.step;
    let { value, updatedInputFieldsValues } = checkForUniqueField(
      event,
      { step, type, name, value: target.value },
      inputFieldsValues
    );
    if (type == "textarea") step = target.tabIndex;
    if (type == "dropdown") step = target.inputTargetId;
    if (name == "columnInFile") value = value.toUpperCase();
    if (name == "inputType")
      resetPreviousInputFieldValidationValues(
        updatedInputFieldsValues,
        step,
        value
      );
    (updatedInputFieldsValues as any)[step][name] = value;
    setInputFieldsValues(updatedInputFieldsValues);
    changeInitValues({ ...data, ...{ fields: updatedInputFieldsValues } });
  };

  const addInputField = () => {
    let highestSortOrderValue = findHighestSortOrderValue(allSortOrderValues);
    dataTypeFormat === "Official"
      ? inputFieldsValues.push({
          ...defaultOfficialInputFields,
          sortOrder: highestSortOrderValue + 1,
        })
      : inputFieldsValues.push({
          ...defaultCustomInputFields,
          sortOrder: highestSortOrderValue + 1,
        });
    setInputFieldsValues(inputFieldsValues);
    changeInitValues({ ...data, ...{ fields: inputFieldsValues } });
  };

  const renderInputFormFields = (
    inputField: inputFieldProps,
    keyId: number,
    fieldInputValue: dataTypeFields,
    targetId: number
  ) => {
    let inputClassName = "flex-1";
    if (
      (inputField.label === "Column in File" && dataTypeFormat === "Custom") ||
      inputField.label === "Field Description"
    ) {
      inputClassName = "";
    }
    return (
      <div key={keyId} className={`${inputField.name} ${inputClassName}`}>
        {inputField.type === "text" && inputField.label === "Field Name" && (
          <TextField
            key={inputField.name + keyId + targetId}
            {...inputField}
            values={fieldInputValue}
            handleChange={handleChange}
            isInputType={true}
            inputTargetId={targetId}
            notEditable={fieldInputValue?.nonEditable}
            notEditableClass={
              dataTypeFormat === "Official" && fieldInputValue?.nonEditable
                ? "mt-2.5"
                : ""
            }
            errorFunc={(input: string) => {
              allFieldNames.splice(targetId, 1);
              const isUsed =
                allFieldNames.findIndex(
                  (value: string) => input.toUpperCase() === value.toUpperCase()
                ) >= 0;
              isUsed === true
                ? updateFormError({ fieldErr: true })
                : updateFormError({ fieldErr: false });
              return isUsed;
            }}
          />
        )}
        {inputField.type === "text" &&
          inputField.label === "Column in File" &&
          dataTypeFormat === "Official" && (
            <TextField
              key={inputField.name + keyId + targetId}
              {...inputField}
              values={fieldInputValue}
              handleChange={handleChange}
              isInputType={true}
              inputTargetId={targetId}
              errorFunc={(input: string) => {
                input = input.toUpperCase();
                const characters = Array.from(input);
                let isDuplicateColumnExists = false;
                if (characters.length > 2) {
                  return true;
                }
                const regex = new RegExp("[A-Z]");
                for (let i = 0; i < characters.length; i++) {
                  if (!regex.test(characters[i])) {
                    return true;
                  }
                }
                allColumnValues.splice(targetId, 1);
                const filteredAllColumnValues = allColumnValues.filter(
                  (value) => value != ""
                );

                if (filteredAllColumnValues.length > 1) {
                  isDuplicateColumnExists =
                    filteredAllColumnValues.filter(
                      (item, index) =>
                        filteredAllColumnValues.indexOf(item) != index
                    ).length > 0;
                }

                const isUsed =
                  filteredAllColumnValues.findIndex(
                    (value: string) => input === value
                  ) >= 0;
                isUsed === true || isDuplicateColumnExists
                  ? updateFormError({ columnErr: true })
                  : updateFormError({ columnErr: false });
                return isUsed;
              }}
              maxLength={2}
              inputClasses="uppercase"
            />
          )}

        {inputField.type === "dropdown" && (
          <Dropdown
            key={inputField.name + keyId + targetId}
            {...inputField}
            values={fieldInputValue}
            handleChange={handleChange}
            options={inputField.options}
            isInputType={true}
            inputTargetId={targetId}
            notEditable={fieldInputValue?.nonEditable}
            notEditableClass={
              dataTypeFormat === "Official" && fieldInputValue?.nonEditable
                ? "mt-2.5"
                : ""
            }
            errorFunc={(input: string) => {
              if (input === "0" || input == "") {
                return true;
              }
              return false;
            }}
          />
        )}
      </div>
    );
  };

  const closeInputFieldBox = (fieldIndex: number) => {
    inputFieldsValues.splice(fieldIndex, 1);
    setInputFieldsValues(inputFieldsValues);
    changeInitValues({ ...data, ...{ fields: inputFieldsValues } });
  };

  const showDrawer = (event: any, index: number) => {
    event.preventDefault();
    setOpenDrawer(true);
    setCurrentFieldIndex(index);
  };

  useEffect(() => {
    setInputFieldsValues(data.fields);
  }, [dataTypeFormat]);

  useEffect(() => {
    setInputFieldsValues(data.fields);
  }, [data]);

  return (
    <>
      {inputFieldsValues.map(
        (fieldInputValue: dataTypeFields, inputIndex: number) => {
          return (
            <div key={inputIndex}>
              <div className="rounded-t-lg overflow-hidden border border-gray-400 bg-white mt-4 py-4 px-4">
                {!fieldInputValue?.nonEditable && (
                  <CloseCrossIcon
                    className="float-right m-0 h-2 w-1"
                    onClick={() => closeInputFieldBox(inputIndex)}
                  />
                )}

                <div
                  className={`flex items-stretch gap-6 undefined mt-5 ${
                    dataTypeFormat == "Custom" ? "input-field-items" : ""
                  }`}
                >
                  {inputFormFields.map((inputField: any, keyId: number) => {
                    return renderInputFormFields(
                      inputField,
                      keyId,
                      fieldInputValue,
                      inputIndex
                    );
                  })}
                </div>
                <div className={dataTypeFormat == "Custom" ? "w-11/12" : ""}>
                  {inputFormFields.map((inputField: any, keyId: number) => {
                    if (
                      inputField.name == "description" &&
                      dataTypeFormat == "Custom"
                    ) {
                      return (
                        <TextAreaField
                          key={inputField.name + keyId + inputIndex}
                          {...inputField}
                          values={fieldInputValue}
                          handleChange={handleChange}
                          isInputType={true}
                          inputTargetId={inputIndex}
                          isOptional={true}
                        />
                      );
                    }
                    return true;
                  })}
                </div>
              </div>
              <div className="rounded-b-lg overflow-hidden border-b border-l border-r border-gray-400 bg-white px-4 w-full py-4 mb-8">
                {(fieldInputValue?.validationType ||
                  fieldInputValue?.required) && (
                  <div>
                    <span className="font-bold text-base">
                      {" "}
                      Field Validation
                    </span>
                    <br></br>
                    <div className="text-base mb-5">
                      {fieldInputValue?.required && (
                        <span className="mr-5">
                          Required: {fieldInputValue?.required ? "Yes" : ""}
                        </span>
                      )}
                      {fieldInputValue?.validationType &&
                        (fieldInputValue?.validationType === "Range" ? (
                          <>
                            <span className="mr-5">
                              Field Validation Type: Range
                            </span>
                            <span>
                              {`Minimum Value: ${fieldInputValue?.range?.min}   Maximum Value: ${fieldInputValue?.range?.max}`}
                            </span>
                          </>
                        ) : fieldInputValue.validationType ===
                          "AcceptableValues" ? (
                          <>
                            <span className="mr-5">
                              Field Validation Type: Acceptable Values
                            </span>
                            <span>
                              Acceptable Values:{" "}
                              {Object.keys(fieldInputValue?.acceptableValues!)
                                .map(
                                  (val) =>
                                    fieldInputValue?.acceptableValues![val]
                                )
                                .join(", ")}
                            </span>
                          </>
                        ) : fieldInputValue.validationType ===
                          "MaxCharacters" ? (
                          <>
                            <span className="mr-5">
                              Field Validation Type: Max Characters
                            </span>
                            <span>
                              Maximum: {`${fieldInputValue.maxCharacter}`}
                            </span>
                          </>
                        ) : fieldInputValue.validationType === "Date" ? (
                          <>
                            <span className="mr-5">
                              Field Validation Type: Date
                            </span>
                            <span>
                              Date Format: {`${fieldInputValue.dateFormat}`}
                            </span>
                          </>
                        ) : (
                          {}
                        ))}
                    </div>
                  </div>
                )}
                <div className="flex flex-row">
                  <button
                    type="button"
                    className="cursor-pointer border-solid border-blue-color border text-blue-color bg-white hover:bg-hover-color hover:border-hover-color font-open-sans font-normal rounded px-4 py-2 text-center dark:hover:border-hover-color dark:bg-white mr-[17px]"
                    onClick={(e) => showDrawer(e, inputIndex)}
                  >
                    {fieldInputValue.validationType || fieldInputValue.required
                      ? "Edit Validation"
                      : "Add Validation"}
                  </button>
                  <div className="flex justify-center items-center px-5">
                    {dataTypeFormat === "Official" &&
                      !fieldInputValue?.nonEditable && (
                        <Checkbox
                          key={inputIndex}
                          keyId={inputIndex}
                          label={""}
                          name={"isScoreKey"}
                          type={"checkbox"}
                          options={[
                            {
                              label: "Field to be displayed in Student Folder",
                              value: true,
                            },
                          ]}
                          values={fieldInputValue}
                          handleChange={(e) => handleChange(e, inputIndex)}
                          disabled={false}
                          children={undefined}
                        />
                      )}
                    {!fieldInputValue.nonEditable && (
                      <Checkbox
                        key={inputIndex + 1}
                        keyId={inputIndex}
                        label={""}
                        name={"isUnique"}
                        type={"checkbox"}
                        options={[
                          {
                            label: "Mark as sub test type",
                            value: false,
                          },
                        ]}
                        values={fieldInputValue}
                        handleChange={(e) => handleChange(e, inputIndex)}
                        disabled={false}
                        children={undefined}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        }
      )}
      <span
        onClick={addInputField}
        className="cursor-pointer border-solid border-blue-color border text-blue-color bg-white hover:bg-hover-color hover:border-hover-color font-open-sans font-normal rounded px-4 py-2 text-center dark:hover:border-hover-color dark:bg-white mr-[17px]"
      >
        Add New Field +{" "}
      </span>
      {openDrawer && (
        <AddFieldValidation
          initialValues={data}
          fieldData={inputFieldsValues[currentFieldIndex]}
          currentFieldIndex={currentFieldIndex}
          changeInitValues={changeInitValues}
          drawerStatus={openDrawer}
          closeDrawer={onClose}
        />
      )}
    </>
  );
};
